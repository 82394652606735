import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  Collapse,
  Autocomplete,
  Checkbox,
  TextField,
  Button,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Download as DownloadIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import styled from "@mui/material/styles/styled";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function OverviewTable(props) {
  const title = props.title || "";
  const rows = props.rows || [];
  const actions = props.actions || [];
  const specialActions = props.specialActions || [];
  const filters = props.filters || [];
  const headCells = props.headCells || [];
  const rowsPerPageOptions = props.rowsPerPage || [5, 10, 20];
  const standardRowsPerPage = props.standardRowsPerPage || 10;

  function descendingComparator(a, b, orderBy) {
    if (!orderBy) return 0;
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function filterRows(filters) {
    let res = rows;
    for (const key in filters) {
      if (filters[key].length > 0) {
        if (key === "naam") {
          res = res.filter((item) =>
            item[key].toLowerCase().includes(filters[key].toLowerCase())
          );
        } else {
          res = res.filter((item) => filters[key].includes(item[key]));
        }
      }
    }
    return res;
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="center"
              sx={{ fontWeight: "bold" }}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.sort ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function EnhancedTableToolbar({ inputFilters, setInputFilters, filteredItems }) {
    function exportToExcel(tableName, filteredItems) {
      const uniqueID = Date.now(); // Generating a unique ID based on the current timestamp
      const filename = `${tableName}_${uniqueID}.xlsx`; // Constructing the filename
      const filteredColumnTitles = headCells.filter(title => title.id !== "actions");

      // Convert data to Excel format
      const data = [filteredColumnTitles.map(title => title.label), ...filteredItems.map(item => filteredColumnTitles.map(title => item[title.id]))];
    
      // Prepare Excel file
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    
      // Save Excel file
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      saveAs(blob, filename);
    }
    

    const [filterOpen, setFilterOpen] = useState(filters.length > 0);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
      <>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 100%", fontWeight: "bold" }}
            variant="h5"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
          {filters.length > 0 && (
            <Tooltip
              sx={{ mr: 2 }}
              title="Filter list"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}

          <Button
            key="Export"
            variant="contained"
            endIcon={<DownloadIcon />}
            onClick={() => (exportToExcel(title, filteredItems))}
            sx={{mr: 2}}
          >
            Export
          </Button>
          {specialActions.map((action) => {
            return action.type.toLowerCase() === "button" ? (
              <Button
                key={action.title}
                variant="contained"
                endIcon={action.icon}
                onClick={action.onClick || (() => {})}
                sx={action.style || {}}
              >
                {action.title}
              </Button>
            ) : (
              <Tooltip
                key={action.title}
                sx={action.style || {}}
                title={action.title}
                onClick={action.onClick || (() => {})}
              >
                <IconButton>{action.icon}</IconButton>
              </Tooltip>
            );
          })}
        </Toolbar>

        <Collapse in={filterOpen}>
          <Toolbar>
            {filters.map((filter) => {
              if (filter.type.toLowerCase() === "textfield") {
                return (
                  <TextField
                    key={filter.title}
                    sx={filter.style || {}}
                    label={filter.title}
                    placeholder={filter.title}
                    onChange={(e) => {
                      setInputFilters({
                        ...inputFilters,
                        [filter.id]: e.target.value,
                      });
                    }}
                  />
                );
              } else if (filter.type.toLowerCase() === "autocomplete") {
                return (
                  <Autocomplete
                    key={filter.title}
                    multiple
                    id="checkboxes-tags-demo"
                    limitTags={filter.limitTags || -1}
                    options={filter.options}
                    disableCloseOnSelect
                    sx={filter.style || {}}
                    getOptionLabel={(option) => option.naam}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.naam}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={filter.title}
                        placeholder={filter.title}
                      />
                    )}
                    onChange={(e, value) => {
                      setInputFilters({
                        ...inputFilters,
                        [filter.id]: value.map((value) => value.naam),
                      });
                    }}
                  />
                );
              } else {
                return null;
              }
            })}
          </Toolbar>
        </Collapse>
      </>
    );
  }

  function EnhancedTable() {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(standardRowsPerPage);
    const [inputFilters, setInputFilters] = useState({});

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const filteredRows = React.useMemo(
      () => filterRows(inputFilters),
      [inputFilters]
    );

    const visibleRows = React.useMemo(
      () =>
        stableSort(filteredRows, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        ),
      [order, orderBy, page, rowsPerPage, filteredRows]
    );

    const StyledTableCell = styled(TableCell)({
      padding: 4,
    });

    return (
      <>
        <EnhancedTableToolbar
          inputFilters={inputFilters}
          setInputFilters={setInputFilters}
          filteredItems={filteredRows}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
            />
            <TableBody>
              {visibleRows.map((item, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {headCells.map((headCell) => {
                      if (headCell.id === "actions") {
                        return null;
                      } else {
                        return (
                          <StyledTableCell
                            key={headCell.id}
                            align="center"
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{
                              p: 2,
                              ...(headCell.style || {}),
                            }}
                          >
                            {headCell.type === "date"
                              ? item[headCell.id] !== ""
                                ? dayjs(item[headCell.id]).format("YYYY-MM-DD")
                                : "-"
                              : item[headCell.id]}
                          </StyledTableCell>
                        );
                      }
                    })}

                    {actions.map((action) => (
                      <StyledTableCell
                        key={action.title}
                        align="center"
                        sx={{
                          flex: 1,
                        }}
                      >
                        <Tooltip
                          title={action.title}
                          onClick={() => action.onClick(item.id)}
                        >
                          <IconButton>{action.icon}</IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTable />
      </Paper>
    </Box>
  );
}
export default OverviewTable;
