import { Grid } from "@mui/material";
import { useParams, useNavigate, Link } from "react-router-dom";
import { axiosAuth } from "../interceptors";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  TextField,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Box,
  MenuItem,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import DatePickerClear from "../components/DatePickerClear";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";

export default function EditItem() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [input, setInput] = useState();

  const [categories, setCategories] = useState([]);
  const [churches, setChurches] = useState([]);
  const [persons, setPersons] = useState([]);

  function fetchitemData() {
    axiosAuth
      .get(process.env.REACT_APP_SERVER_HOST + "/items" + "/" + id)
      .then((response) => {
        response.data.archief = response.data.archief ? "true" : "false";
        setInput({
          name: response.data.naam,
          category: response.data.categorie_naam,
          church: response.data.kerk_naam,
          archive: response.data.archief === "true" ? true : false,
          startDate: response.data.begindatum
            ? dayjs(response.data.begindatum).format("YYYY-MM-DD HH:mm:ss")
            : "",
          endDate: response.data.einddatum
            ? dayjs(response.data.einddatum).format("YYYY-MM-DD HH:mm:ss")
            : "",
          mailSignal: response.data.signaal_mail
            ? dayjs(response.data.signaal_mail).format("YYYY-MM-DD HH:mm:ss")
            : "",
          visualSignal: response.data.signaal_visueel
            ? dayjs(response.data.signaal_visueel).format("YYYY-MM-DD HH:mm:ss")
            : "",
          persons: response.data.personen || [],
          note: response.data.notitie || "",
        });
      });

    axiosAuth
      .get(process.env.REACT_APP_SERVER_HOST + "/categorieen")
      .then((response) => {
        setCategories(response.data);
      });

    axiosAuth
      .get(process.env.REACT_APP_SERVER_HOST + "/kerkgebouwen")
      .then((response) => {
        setChurches(response.data);
      });

    axiosAuth
      .get(process.env.REACT_APP_SERVER_HOST + "/user")
      .then((response) => {
        setPersons(response.data);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();

    for (const category of categories) {
      if (category.naam === input.category) {
        input.category = category.id;
      }
    }

    for (const church of churches) {
      if (church.naam === input.church) {
        input.church = church.id;
      }
    }

    console.log(input.archive);

    axiosAuth
      .patch(process.env.REACT_APP_SERVER_HOST + "/items/" + id, {
        name: input.name,
        category: input.category,
        church: input.church,
        archive: input.archive ? 1 : 0,
        startDate: input.startDate || "",
        endDate: input.endDate || "",
        mailSignal: input.mailSignal || "",
        visualSignal: input.visualSignal || "",
        persons: input.persons,
        note: input.note,
      })
      .then((response) => {
        navigate("/item/" + id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchitemData();
  }, []);

  function handleChange(e) {
    // setItems({
    //   ...items,
    //   [e.target.name]: e.target.value,
    // });
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  return (
    input && (
      <>
        <Button variant="contained" color="primary" component={Link} to={"/"}>
          Terug
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
          component="form" onSubmit={handleSubmit} noValidate
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              width: "80%",
              marginTop: "20px",
            }}
          >
            {/* Left Side */}
            <Box>
              {/* Your left side form fields here */}
              <TextField
                id="outlined-required"
                label="Naam"
                name="name"
                onChange={handleChange}
                defaultValue={input.name}
                sx={{ width: "100%", mt: 4 }}
              />
              <TextField
                select
                required
                label="Categorie"
                name="category"
                id="category"
                value={input.category}
                onChange={handleChange}
                autoFocus
                sx={{ width: "100%", mt: 1, mb: 1 }}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.naam}>
                    {category.naam}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="Kerk"
                id="church"
                name="church"
                value={input.church}
                onChange={handleChange}
                autoFocus
                sx={{ width: "100%", mt: 1, mb: 1 }}
              >
                {churches.map((church) => (
                  <MenuItem key={church.id} value={church.naam}>
                    {church.naam}
                  </MenuItem>
                ))}
              </TextField>
              <DatePickerClear
                onChange={(date) =>
                  setInput({
                    ...input,
                    startDate: dayjs(date.$d).format("YYYY-MM-DD HH:mm:ss"),
                  })
                }
                value={input.startDate == null ? null : dayjs(input.startDate)}
                onClear={() => setInput({ ...input, startDate: null })}
                label="Begindatum"
                id="startdate"
                sx={{ width: "100%", mt: 1, mb: 1 }}
              />
              <DatePickerClear
                onChange={(date) =>
                  setInput({
                    ...input,
                    endDate: dayjs(date.$d).format("YYYY-MM-DD HH:mm:ss"),
                  })
                }
                value={input.endDate == null ? null : dayjs(input.endDate)}
                onClear={() => setInput({ ...input, endDate: null })}
                label="Einddatum"
                id="enddate"
                sx={{ width: "100%", mt: 1, mb: 1, ml: 5 }}
              />
              <DatePickerClear
                onChange={(date) =>
                  setInput({
                    ...input,
                    mailSignal: dayjs(date.$d).format("YYYY-MM-DD HH:mm:ss"),
                  })
                }
                value={
                  input.mailSignal == null ? null : dayjs(input.mailSignal)
                }
                onClear={() => setInput({ ...input, mailSignal: null })}
                label="Mail signaal"
                id="mailsignal"
                sx={{ width: "100%", mt: 1, mb: 1 }}
              />
              <DatePickerClear
                onChange={(date) =>
                  setInput({
                    ...input,
                    visualSignal: dayjs(date.$d).format("YYYY-MM-DD HH:mm:ss"),
                  })
                }
                value={
                  input.visualSignal == null ? null : dayjs(input.visualSignal)
                }
                onClear={() => setInput({ ...input, visualSignal: null })}
                label="Visueel signaal"
                id="visualsignal"
                sx={{ width: "100%", mt: 1, mb: 1, ml: 5 }}
              />
            </Box>

            {/* Right Side */}
            <Box sx={{ mt: 2 }}>
              {/* Your right side form fields here */}
              <Autocomplete
                key="persons"
                multiple
                id="checkboxes-tags-demo"
                limitTags={2}
                options={persons}
                value={input.persons}
                sx={{ width: "100%", mt: 2 }}
                disableCloseOnSelect
                getOptionLabel={(option) => option.email}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={input.persons.some(
                        (person) => person.id === option.id
                      )}
                    />
                    {option.voornaam && option.achternaam
                      ? option.voornaam + " " + option.achternaam
                      : option.email}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Personen"
                    placeholder="Personen"
                  />
                )}
                onChange={(e, value) => {
                  setInput({ ...input, persons: value });
                }}
              />
              <TextField
                id="outlined-required"
                label="Notitie"
                name="note"
                onChange={handleChange}
                defaultValue={input.note}
                multiline
                rows={6}
                sx={{ width: "100%", mt: 2 }}
              />
              <RadioGroup
                row
                id="archief"
                value={input.archive}
                onChange={(event) =>
                  setInput({
                    ...input,
                    archive: event.target.value === "true" ? true : false,
                  })
                }
                autoFocus
                sx={{ width: "100%", mt: 2 }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Gearchiveerd"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Niet gearchiveerd"
                />
              </RadioGroup>
            </Box>
          </Box>
          {/* Centered Button */}
          <Box sx={{ gridColumn: "1 / -1", textAlign: "center" }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Opslaan
            </Button>
          </Box>
        </Box>
      </>
    )
  );
}
