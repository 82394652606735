import React, { useEffect, useState } from "react";
import { axiosAuth } from "../interceptors";
import {
  AddRounded as AddRoundedIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import OverviewTable from "../components/OverviewTable";
import { useNavigate } from "react-router-dom";

export default function Gebouwen() {
  const navigate = useNavigate();

  const [churches, setChurches] = useState([]);

  const fetchitemData = () => {
    axiosAuth(process.env.REACT_APP_SERVER_HOST + "/kerkgebouwen").then(
      (response) => {
        setChurches(response.data);
      }
    );
  };

  useEffect(() => {
    fetchitemData();
  }, []);

  const headCells = [
    {
      id: "naam",
      label: "Naam",
      sort: true,
      style: { width: "25%" },
    },
    {
      id: "adres",
      label: "Adres",
      sort: true,
      style: { width: "25%" },
    },
    {
      id: "postcode",
      label: "Postcode",
      sort: true,
      style: { width: "20%" },
    },
    {
      id: "plaats",
      label: "Plaats",
      sort: true,
      style: { width: "20%" },
    },
    {
      id: "actions",
      label: "Acties",
      sort: false,
      style: { width: "10%" },
    },
  ];

  return (
    <OverviewTable
      title="Gebouwen"
      headCells={headCells}
      rows={churches}
      actions={[
        {
          icon: <EditIcon />,
          title: "Bewerken",
          onClick: (itemId) => {
            navigate("/gebouwen/edit/" + itemId);
          },
        },
      ]}
      specialActions={[
        {
          icon: <AddRoundedIcon />,
          title: "Toevoegen",
          onClick: (event, rowData) => {
            navigate("/gebouwen/create");
          },
          type: "button",
        },
      ]}
    />
  );
}
