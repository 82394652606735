import React, { useEffect, useState } from "react";
import { axiosAuth } from "../interceptors";
import {
  AddRounded as AddRoundedIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import OverviewTable from "../components/OverviewTable";
import { useNavigate } from "react-router-dom";

export default function Categorieen() {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);

  const fetchitemData = () => {
    axiosAuth(process.env.REACT_APP_SERVER_HOST + "/categorieen").then(
      (response) => {
        setCategories(response.data);
      }
    );
  };

  useEffect(() => {
    fetchitemData();
  }, []);

  const headCells = [
    {
      id: "naam",
      label: "Naam",
      sort: true,
      style: { width: "40%" },
    },
    {
      id: "omschrijving",
      label: "Omschrijving",
      sort: true,
      style: { width: "50%" },
    },
    {
      id: "actions",
      label: "Acties",
      sort: false,
      style: { width: "10%" },
    },
  ];

  return (
    <OverviewTable
      title="Categorieën"
      headCells={headCells}
      rows={categories}
      actions={[
        {
          icon: <EditIcon />,
          title: "Bewerken",
          onClick: (itemId) => {
            navigate("/categorieen/edit/" + itemId);
          },
        },
      ]}
      specialActions={[
        {
          icon: <AddRoundedIcon />,
          title: "Toevoegen",
          onClick: (event, rowData) => {
            navigate("/categorieen/create");
          },
          type: "button",
        },
      ]}
    />
  );
}
